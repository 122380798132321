export const environment = {
	automaticAuthorizerFrontEndURI: 'https://autorizador.qa.credilowcreditos.com.ar/login',
	automaticAuthorizerURI: 'https://automatic-authorizer-service.api.qa.credilowcreditos.com.ar/api',
	credilowFrontEndURI: 'https://www.qa.credilow.com.ar/',
	creditosURI: 'https://sistema.qa.credilowcreditos.com.ar/',
	googleApiKey: 'AIzaSyDohuxJ0QwtC3ZRYtBZStw5hDw0xhvPBuA',
	identityServiceURI: 'https://identity-service.api.qa.credilowcreditos.com.ar/',
	production: false,
	toastrTimeOut: 5000,
}
